import React from "react";
import "./footer.css";
import { logo, ig, fb, linkedin } from "./imports";


const Footer = () => {
  return (

      <div className="nazdaa__footer section__padding">
        <div className="nazdaa__footer-link">
          <div className="nazdaa__footer-links_logo">
            <img src={logo} alt="logo" />
            <p>Cagayan De Oro, Lumbia, Misamis Oriental, All Rights Reserved</p>
          </div>

          <div className="nazdaa__footer-links_div">
            <h4>Links</h4>
            <p>
              <a href="#home">Top</a>
            </p>
            <p>
              <a href="#services">Services</a>
            </p>
            <p>
              <a href="#projects">Projects</a>
            </p>
            <p>
              <a href="#about">Why Us?</a>
            </p>
            <p>
              <a href="/contact">Contact Us</a>
            </p>
            <p>
              <a href="/about">About Us</a>
            </p>
          </div>
          <div className="nazdaa__footer-links_div-2">
            <h4>Company</h4>
            <p>Terms & Conditions</p>
            <p>Privacy Policy</p>
          </div>
          <div className="nazdaa__footer-links_div-3">
            <h4>Get in touch</h4>
            <p>Lumbia, Cagayan De Oro</p>
            <p>+63 9056106330</p>
            <p>inquiry@nazdaa.net</p>
            <div className="nazdaa__footer-links_div-3-1">
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ig} alt="Instagram" />
              </a>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="LinkedIn" />
              </a>
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fb} alt="Facebook" />
              </a>
            </div>
          </div>
        </div>
        <div className="nazdaa__footer-rights">
          <h6>© 2023 Nazdaa. All rights reserved.</h6>
        </div>
      </div>

  );
};

export default Footer;
