import React from 'react'
import './servicesCard.css'

const ServicesCard = ({ title, text, imageUrl }) => {
  return (
    <div className="nazdaa__servicesCard">
      <div className="nazdaa_servicesCard-content">
        <div className="nazdaa_servicesCard-content__image">
          <img src={imageUrl} alt={title} />
        </div>
        <div className="nazdaa__servicesCard-content__information">
        <div className="nazdaa__servicesCard-content__heading">
          <h1>{title}</h1>
        </div>
        <div className="nazdaa_servicesCard-content__text">
          <p>{text}</p>
        </div>
        </div>
      </div>
    </div>
  );
}


export default ServicesCard