import React from "react";
import "./cable.css";

function Cable() {
    console.log("Show");
  return (
    <div className="container">
      <div className="blockOne">
        <div className="blockTwo">
          <div className="cable">
            <div className="data"></div>
            <div className="flasher"></div>
          </div>
          <div className="cable">
            <div className="data"></div>
            <div className="data"></div>
            <div className="data"></div>
          </div>
          <div className="cable">
            <div className="data"></div>
            <div className="flasher"></div>
          </div>
          <div className="cable">
            <div className="data"></div>
            <div className="flasher"></div>
          </div>
          <div className="cable">
            <div className="data"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cable;
