import React, { useEffect } from "react";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/Footer";
import ProjectsCard from "../../../pages/index/projectsCard/ProjectsCard";
import "./projectsPage.css";
import { npc2, aristocrat, cabuyao, goldcrest, vincent, tmtc, dreamworks, cafemaximo, internet } from "./imports";

function ProjectsPage() {
  useEffect(() => {
    document.title = "Nazdaa Projects"; // Update the title here
  }, []);
  return (
    <div>
      <Navbar />
      <div className="nazdaa__projectsPage-heading">
        <div className="nazdaa__projectsPage-heading__content">
          <h1>Showcasing Our Transformative IT Endeavors</h1>
          <h3>
            Nazdaa's Projects will drive innovation and success through
            transformative solutions.
          </h3>
        </div>
      </div>
      <div className="nazdaa__projectsPage-content section__padding">
        <h1 className="nazdaa__projectsPage-content__h1">
          Projects made by the team
        </h1>

        <div className="nazdaa__projectsPage-content__projectsContainer section__padding">

        <div className="nazdaa__projectsPage-content__projectsCard">
        <ProjectsCard
            imgUrl={internet}
            title={"Internet Installation"}
            date={"June 2022 - Present"}
            text={
              "We started laying Fiber Optic Cables to provide unlimited Internet connectivity to the households of Sunnyville subd, National Housing Authority (NHA), 722 Socialized Housing Units of Lumbia Cagayan De Oro as well as in Opol.With this project, we used the 4 port GPON Optical Line Terminal (OLT) to achieve access networks like Fiber To The Home (FTTH).This OLT serves as the core component of the optical access network from our central office to provide a fiber optic interface to the user’s fiber optic network thru a number of optical network units (ONU)."
            }
          />
        </div>

        <div className="nazdaa__projectsPage-content__projectsCard">
            <ProjectsCard
              imgUrl={goldcrest}
              title={"Goldcrest Marketing Inc."}
              date={"May 2023 - June 2023"}
              text={
                "Goldcrest Marketing in CDO availed our services for their SCS and CCTV requirement. SCS is a type of infrastructure that supports the performance of an organisation's cabling system or network that binds all the devices used within the business together – providing a reliable and versatile solution. CCTV, on the other hand,  is also important to minimize theft and property damage by having the ability to monitor a property's movements and who enters and exits it."
              }
            />
          </div>
        
          <div className="nazdaa__projectsPage-content__projectsCard">
            <ProjectsCard
              imgUrl={cafemaximo}
              title={"Cafe Maximo"}
              date={"September 2022"}
              text={
                "Cafe Maximo located at Cagayan De Oro is Nazdaa IT Solutions' client for WiFi vouchers. WiFi vouchers allows businesses, like Cafe Maximo , to limit guest access to their WiFi networks. WiFi vouchers can also limit download, upload of users and the number of devices connected to the network. This prevents unauthorized non-customers from using their internet."
              }
            />
          </div>
        
          <div className="nazdaa__projectsPage-content__projectsCard">
            <ProjectsCard
              imgUrl={aristocrat}
              title={"The Aristocrat"}
              date={"March 2021"}
              text={
                "Aristocrat, lovingly known as Manila's famous restaurant, and labeled as 'Home of the Best Chicken Barbecue in Town', has been serving Filipino Food for more than 84 years.Nazdaa IT Solutions was fortuitous enough to be of service to this prominent and notable institution. It is indeed a privilege to be elected as a contractor for the installation of their Structured Cabling Systems requirements."
              }
            />
          </div>
        
          <div className="nazdaa__projectsPage-content__projectsCard">
            <ProjectsCard
              imgUrl={dreamworks}
              title={"Dreamworks"}
              date={"July 2020"}
              text={
                "Nazdaa I.T Solutions had the privilege of providing DreamWorks with a Learning Management System, offering a user-friendly platform to manage courses, deliver engaging content, and track performance, streamlining the educational experience and fostering a technologically advanced learning environment."
              }
            />
          </div>
        
          <div className="nazdaa__projectsPage-content__projectsCard">
            <ProjectsCard
              imgUrl={vincent}
              title={"St. Vincent De Ferrer College of Camarin"}
              date={"June 2020"}
              text={
                "Nazdaa I.T Solutions had the privilege of providing SVFDCCI with Campus Wifi and a Learning Management System, offering seamless internet access and a user-friendly platform to manage courses, deliver engaging content, and track performance, streamlining the educational experience and fostering a technologically advanced learning environment."
              }
            />
          </div>
        
          <div className="nazdaa__projectsPage-content__projectsCard">
            <ProjectsCard
              imgUrl={tmtc}
              title={"The Manila Times College"}
              date={"May 2020"}
              text={
                "At Nazdaa I.T Solutions, we take pride in providing our expert curriculum development services to The Manila Times College, creating dynamic and forward-looking academic programs that empower students for success in today's tech-driven world."
              }
            />
          </div>
          
        <div className="nazdaa__projectsPage-content__projectsCard">
            <ProjectsCard
              imgUrl={npc2}
              title={"National Police College"}
              date={"March 2019"}
              text={
                "'To institutionalize responsive education and training programs for public safety leaders and practitioners', we at Nazdaa I.T Solutions with all our efforts serve as systems integrators by providing them a 200Mbps Internet bandwidth through NextOne by Mr. Bert Ong. Since they are planning to engage in new technologies, we consider designed a campus network so that they can access the Internet anywhere within the camp."
              }
            />
          </div>

          <div className="nazdaa__projectsPage-content__projectsCard">
            <ProjectsCard
              imgUrl={cabuyao}
              title={"Cabuyao City Hall"}
              date={"September 2017"}
              text={
                "The Municipal Office of Cabuyao City adapted the so called Structured Cabling Systems or SCS in the entire building. SCS by the way is a type of infrastructure that supports the performance of an organisation's cabling system or network that binds all PCs, phones and other devices used within the business together – providing a reliable and versatile solution to a wide range of communication requirements."
              }
            />
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ProjectsPage;
