import React from 'react'
import './projectsCard.css'
const ProjectsCard = ({ imgUrl, text, title, date }) => {
  return (
    <div className="nazdaa__projectsCard">
      <div className="nazdaa__projectsCard-container_article-image">
        <img src={imgUrl} alt="No Connection" />
      </div>
      <div className="nazdaa__projectsCard-container_article-content">
        <div>
          <h3>{title}</h3>
          <p>{text}</p>
          
        </div>
      </div>
      <p>{date}</p>
      <p className="read_button"></p>
    </div>
  )
}

export default ProjectsCard