import React from "react";
import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/Footer";

function Cabuyao() {
  return (
    <div>
      <Navbar />
      Cabuyao
      <Footer />
    </div>
  );
}

export default Cabuyao;
