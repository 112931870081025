import React from "react";
import "./services.css";
import ServicesCard from "../../servicesCard/ServicesCard";
import {
  cablereel,
  security,
  browser,
  appdev,
  server,
  learning,
  cctv,
  onlinemoney,
  signup,
  inventory,
  sales,
  campuswifi
} from "./imports";

const cardsData = [
  {
    title: "Structured Cabling",
    text: "Providing an expertise in the design and installation of cabling infrastructures for schools, restaurants, and workplaces.",
    imageUrl: cablereel, // Import the image for this card
  },
  {
    title: "Command Center Set Up",
    text: "Go-to provider for installing and setting up Command Center for surveillance and monitoring applications.",
    imageUrl: security, // Import the image for this card
  },
  {
    title: "Internet Services",
    text: "Internet service provider for fast and capable internet connections.",
    imageUrl: browser, // Import the image for this card
  },
  {
    title: "Mobile Applications",
    text: "Development services of cutting-edge technologies for mobile applications.",
    imageUrl: appdev, // Import the image for this card
  },
  {
    title: "Network Installation & Configuration",
    text: "Expert solution for installing and configuring network.",
    imageUrl: server, // Import the image for this card
  },
  {
    title: "Campus Wifi",
    text: "Wireless internet for college/university campuses.",
    imageUrl: campuswifi
  },
  {
    title: "Learning Management System (LMS) Platform",
    text: "Software application platform for delivering educational courses for online classes.",
    imageUrl: learning, // Import the image for this card
  },
  {
    title: "CCTV Installation",
    text: "Security surveillance installation.",
    imageUrl: cctv, // Import the image for this card
  },
  {
    title: "Piso Wifi",
    text: "Piso Wifi vending machine setup.",
    imageUrl: onlinemoney, // Import the image for this card
  },
  {
    title: "School Portal System",
    text: "Development of school portals that include enrollment, account, graduation, etc.",
    imageUrl: signup, // Import the image for this card
  },
  {
    title: "Inventory System",
    text: "Tracking, managing, and organizing a company's goods and materials in real-time.",
    imageUrl: inventory,
  },
  {
    title: "Sales System",
    text: "Facilitates the management of sales activities, including customer interactions, order processing, and tracking of sales performance.",
    imageUrl: sales
  },
];

const Services = () => {
  return (
    <div className="nazdaa__services section__padding" id="services">
      <div className="nazdaa_services-heading">
        <h1>Services</h1>
      </div>
      <div className="nazdaa__services-container">
        {cardsData.map((item, index) => (
          <ServicesCard
            title={item.title}
            text={item.text}
            imageUrl={item.imageUrl}
            key={item.title + index}
          />
        ))}
      </div>
      <br />
      <br />
      <br />
      <div className="nazdaa__services-buttonContainer">
        <button className="nazdaa__services-cta">
          <a href="/contact">Contact Us</a>
        </button>
      </div>
    </div>
  );
};

export default Services;
