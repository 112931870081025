import React from "react";
import "./projects.css";
import ProjectsCard from "../../projectsCard/ProjectsCard";
import { aristocrat, cafemaximo, goldcrest, internet } from "./imports";
const Projects = () => {
  return (
    <div className="nazdaa__projects section__padding" id="projects">
      <div className="nazdaa__projects-heading">
        <div className="nazdaa_projects-heading__header">
          <h1 className="header-title">Projects</h1>
          <div className="header-info">
            <h1>Immerse Yourself in Our Showcase of Cutting-Edge Projects and
            Experience Technological Wonders.</h1>
          <a href="/projects">
            <button>View All</button>
          </a>
          </div>
        </div>
        <div className="nazdaa__projects-heading__project">
          <ProjectsCard
            imgUrl={internet}
            title={"Internet Installation"}
            date={"June 2022 - Present"}
            text={
              "We started laying Fiber Optic Cables to provide unlimited Internet connectivity to the households of Sunnyville subd, National Housing Authority (NHA), 722 Socialized Housing Units of Lumbia Cagayan De Oro as well as in Opol.With this project, we used the 4 port GPON Optical Line Terminal (OLT) to achieve access networks like Fiber To The Home (FTTH).This OLT serves as the core component of the optical access network from our central office to provide a fiber optic interface to the user’s fiber optic network thru a number of optical network units (ONU)."
            }
          />
        </div>
      </div>
      <div className="nazdaa__projects-container">
        <div className="nazdaa__projects-container__content">
        <ProjectsCard
            imgUrl={goldcrest}
            title={"Goldcrest Marketing Inc."}
            date={"May 2023 - June 2023"}
            text={
              "Goldcrest Marketing in CDO took our services for their SCS and CCTV requirement. SCS is a type of infrastructure that supports the performance of an organisation's cabling system or network that binds all the devices used within the business together – providing a reliable and versatile solution. CCTV, on the other hand,  is also important to minimize theft and property damage by having the ability to monitor a property's movements and who enters and exits it."
            }
          />

          <ProjectsCard
            imgUrl={aristocrat}
            title={"The Aristocrat"}
            date={"March 2021"}
            text={
              "Aristocrat, lovingly known as Manila's famous restaurant, and labeled as 'Home of the Best Chicken Barbecue in Town', has been serving Filipino Food for more than 84 years.Nazdaa IT Solutions was fortuitous enough to be of service to this prominent and notable institution. It is indeed a privilege to be elected as a contractor for the installation of their Structured Cabling Systems requirements."
            }
          />

          <ProjectsCard
            imgUrl={cafemaximo}
            title={"Cafe Maximo"}
            date={"September 2022"}
            text={
              "Cafe Maximo located at Cagayan De Oro is Nazdaa IT Solutions' client for WiFi vouchers. WiFi vouchers allows businesses, like Cafe Maximo , to limit guest access to their WiFi networks. WiFi vouchers can also limit download, upload of users and the number of devices connected to the network. This prevents unauthorized non-customers from using their internet."
            }
          />

        </div>
      </div>
    </div>
  );
};

export default Projects;
