import React from "react";
import "./aboutData.css";
import About from "../containers/about/About"
import image from "../../../assets/index/aboutus/aboutus.png"
const featuresData = [
  {
    title: "A proven client service model.",
    text: "We are an I.T Solutions business that prides itself on delivering a proven client service model, ensuring that our customers receive exceptional support and tailored solutions for their technology needs.",
  },
  {
    title: "Timely response to any issues.",
    text: "Our efficient support team is always ready to address and resolve technical challenges promptly, minimizing downtime and maximizing productivity for our valued customers.",
  },
  {
    title: "Provide the optimum and customized solutions made for your organization.",
    text: "At our I.T Solutions business, we go beyond generic solutions by providing optimum and customized options specifically tailored to meet the unique requirements of your organization.",
  },
  {
    title: "Cost-efficient services to meet your requirements.",
    text: "Our I.T Solutions business is committed to delivering cost-efficient services that precisely meet your requirements.",
  },
];
const AboutData = () => {
  return (
    <div className="nazdaa__aboutus section__padding" id="aboutdata">
      <div className="nazdaa__aboutus-heading">
        <h1>Why Us?</h1>
      <div className="nazdaa__aboutus-heading-content">
        <div className="imageContainer">
        <img src={image} alt="" srcSet={image} />
        </div>
        </div>
      </div>
      <div className="nazdaa__aboutus-container">
      {featuresData.map((item, index) => (
          <About title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  );
};

export default AboutData;
