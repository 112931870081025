import React from "react";
import { computer1, computer2 } from "../contact/imports";
import "../contact/contact.css";
const Contact = () => {
  return (
    <div className="nazdaa__contact" id="contact">
      <div className="nazdaa__contact-heading section__padding">
        <div className="nazdaa__contact-heading__header">
          <h1>Contact Us</h1>
        </div>
        <div className="nazdaa__contact-heading__content">
          <h3>
            Reach out to us today and let's embark on a collaborative journey
            towards your digital success.
          </h3>
        </div>
      </div>
      <div className="nazdaa__contact-body">
        <div className="nazdaa__contact-body__img1">
          <img src={computer1} alt="computer1" srcSet="" />
        </div>
        <div className="nazdaa__contact-body__content">
          <h1>
            Immerse Yourself in Our Showcase of Cutting-Edge Projects and
            Experience Technological Wonders.
          </h1>
          <button><a href="/contact">CONTACT US</a></button>
        </div>
        <div className="nazdaa__contact-body__img2">
          <img src={computer2} alt="computer2" srcset="" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
