import React, { useState, useEffect } from "react";
import "./navbar.css";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import Logo from "../../assets/index/nav-logo.png";
import Login from "../login/Login";

const Menu = () => (
  <>
    <p>
      <a href="/projects" style={{ cursor: "pointer" }}>
        Projects
      </a>
    </p>
    <p>
      <a href="/about" style={{ cursor: "pointer" }}>
        About Us
      </a>
    </p>
    <p>
      <a href="/contact" style={{ cursor: "pointer" }}>
        Contact Us
      </a>
    </p>
  </>
);

const Navbar = () => {
  //auth
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      /*       fetch("https://nazdaa.net/auth/login/success", {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          throw new Error("Authentication has failed");
        })
        .then((resObject) => {
          setUser(resObject.user);
        })
        .catch((err) => {
          console.log(err);
        }); */
    };
    getUser();
  }, []);
  const [backendData, setBackendData] = useState([{}]);

  useEffect(() => {
    fetch("/api")
      .then((response) => response.json())
      .then((data) => {
        setBackendData(data);
      });
  }, []);

  console.log(user);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleSignInClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  const logout = () => {
    window.open("https://nazdaa.net/auth/logout", "_self");
  };
  return (
    <div className="nazdaa__navbar">
      <div className="nazdaa__navbar-links">
        <div className="nazdaa__navbar-links_logo">
          <a href="/">
            <img src={Logo} alt="logo" />
          </a>
        </div>
        <div className="nazdaa__navbar-links_container">
          <Menu />
        </div>
      </div>
      {/* <div className="nazdaa__navbar-sign">
        {user ? (
          <div className="user-info">
            <div className="display-info">
              <img
                src={user.photos[0].value}
                alt="User Avatar"
                className="user-avatar"
              />
              {user.displayName && !user.username && (
                <p className="user-display-name">{user.displayName}</p>
              )}

              {!user.displayName && user.username && (
                <p className="user-display-name">{user.username}</p>
              )}
            </div>
            <div className="logout__pc">
              <p className="logout" onClick={logout}>
                Logout
              </p>
            </div>
          </div>
        ) : (
          <>
            <p className="sign_in" onClick={handleSignInClick}>
              Sign-in
            </p>
            {isModalOpen && (
              <Login isOpen={isModalOpen} onClose={handleSignInClick} />
            )}
            <button type="button" className="sign_up">
              Sign-up
            </button>
          </>
        )}
      </div> */}
      <div className="nazdaa__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#000000"
            size={27}
            onClick={() => setToggleMenu(false)}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <RiMenu3Line
            color="#000000"
            size={27}
            onClick={() => setToggleMenu(true)}
            style={{ cursor: "pointer" }}
          />
        )}
        {toggleMenu && (
          <div className="nazdaa__navbar-menu_container text-focus-in">
            <div className="nazdaa__navbar-menu_container-links">
              <div className="nazdaa__navbar-menu_container-links-sign">
              {/*   {user ? (
                  <div className="display-info">
                    {user.avatar && user.avatar.length > 0 && (
                      <img
                        src={user.avatar}
                        alt="User Avatar"
                        className="user-avatar"
                      />
                    )}
                    <p className="user-display-name">{user.name}</p>
                  </div>
                ) : (
                  <>
                    <p className="sign_in" onClick={handleSignInClick}>
                      Sign-in
                    </p>
                    {isModalOpen && (
                      <Login isOpen={isModalOpen} onClose={handleSignInClick} />
                    )}
                    <button type="button">Sign-up</button>
                  </>
                )} */}
              </div>
              <Menu />
              <div className="logout__mobile">
                {user && <p onClick={logout}>Logout</p>}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
