import React, { useEffect } from "react";
import "./header.css";
import Hero from "../../../../assets/index/Group 5.png";
import { startAnimation } from "./animation.js";

const Header = () => {
  useEffect(() => {
    startAnimation();
  }, []);

  return (
    <div className="nazdaa__header" id="home">
      <div className="nazdaa__header-background">
        <img src={Hero} alt="header" srcSet={Hero} />
        <div className="nazdaa__header-content">
        <h1>
            <div className="mask">
              <span data-show>Optimizing Services</span>
              <span>Innovating Education</span>
              <span>Seamless Solutions</span>
              <span>Fortifying Security</span>
            </div>
            for the Digital Age
          </h1>
          <p>
            NAZDAA IT Solutions is a company committed to providing premium
            Network and Information Technology services.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
