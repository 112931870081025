import React, { useEffect } from "react";
import { Footer, Navbar } from "./components";
import { Brand, AboutData, Contact } from "./pages/index/";
import { Header, Services, Projects } from "./pages/index/containers/";
import "./App.css";

function App() {
  useEffect(() => {
    document.title = "Nazdaa";
  }, []);
  
  return (
    <div className="App">
      <div className="navbar">
        <Navbar />
      </div>
      <Header />
      <Brand />
      <Services />
      <Projects />
      <AboutData />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
