import React, { useEffect } from "react";
import { Navbar, Footer } from "../../components";
import "./aboutPage.css";
import breaker1 from "../../assets/aboutPage/breaker1.png";

function AboutPage() {
  useEffect(() => {
    document.title = "Nazdaa About"; // Update the title here
  }, []);
  return (
    <div>
      <Navbar />
      <div className="nazdaa__aboutPage">
        <div className="nazdaa__aboutPage__groups section__padding">
          <div className="nazdaa__aboutPage__groupA">
            <div className="nazdaa__aboutPage__groupA-header">
              <p className="lead">Who are we?</p>
              <h1>Nazdaa I.T Solutions</h1>
            </div>
            <div className="nazdaa__aboutPage__groupA-content">
              <p>
                Nazdaa I.T Solutions is an Information and Communications
                Technology company located in Binangonan, Rizal, servicing
                clients nationwide.
              </p>
              <br />
              <p>
                It is the provider of business and education I.T solutions to
                most organizations and educational institutions in the
                Philippines. It is commited to delivering quality advice, sales
                and support in order to assist its clients achieve the maximum
                from their investment.
              </p>
            </div>
          </div>

          <div className="nazdaa__aboutPage__groupB">
            <div id="computer">
              <div id="side"></div>
              <div id="top"></div>
              <div id="front"></div>
              <div id="screen"></div>
              <div id="text">
                <div>$&nbsp;u&#8203;n&#8203;a&#8203;m&#8203;e -a</div>
                <div>
                  d&#8203;o&#8203;t&#8203;|&#8203;n&#8203;o&#8203;t
                  C&#8203;E&#8203;R&#8203;T
                  1&#8203;.&#8203;0&#8203;.&#8203;0&#8203;.&#8203;0
                </div>
                <div>
                  W&#8203;e&#8203;l&#8203;c&#8203;o&#8203;m&#8203;e 2
                  N&#8203;A&#8203;Z&#8203;D&#8203;A&#8203;A&#8203;!&#8203;
                </div>
                <div>
                  c&#8203;o&#8203;n&#8203;g&#8203;r&#8203;a&#8203;t&#8203;u&#8203;l&#8203;a&#8203;t&#8203;i&#8203;o&#8203;n&#8203;s&#8203;!
                  :)
                </div>
              </div>
              <div id="button"></div>
            </div>
          </div>
        </div>

        <div className="nazdaa__aboutPage-content section__padding">
          <p>
            Nazdaa I.T Solutions has been a trusted partner for clients in the{" "}
            Manila area, as well as in Cavite,
             Laguna and in the Visayas region. Working
            closely with our clients allows us to act in their best interests
            over the long term. It also provides a one stop automated solution
            for their trade and industry requirements
          </p>
        </div>

        <div className="nazdaa__aboutPage-breaker1">
          <img src={breaker1} alt="" />
        </div>

        <div className="nazdaa__aboutPage-vision section__padding">
          <p className="lead">Our Vision</p>
          <p className="vision">
            Our Vision is to achieve 100% customer satisfaction by delivering
            quality products and services at an affordable cost. Our forward
            vision is to strive to become an entity in technology based
            corporate solutions, capable of demanding unconditional response
            from the targeted niche. We also believe that for our scope of
            improvisation - sky is the limit and we are always ready to take our
            achievements to the next level. We are growing and would always like
            to remain on the growing streak.
          </p>
        </div>

        <div className="nazdaa__aboutPage-mission section__padding">
          <p className="lead">Our Mission</p>
          <p className="mission">
            Our Mission is to achieve the reputation of a quality, high standard
            & reliable solution & Service Provide Company in the Information and
            Communications Technology industry.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AboutPage;
