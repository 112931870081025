import React from 'react'
import './brand.css'
import { aristocrat, goldcrest, npc } from "./imports"
const Brand = () => {
  return (
    <div className="nazdaa__brand section__padding">
      <div>
        <img className="goldcrest" src={ goldcrest  } alt="goldcrest" />
      </div>
      <div>
        <img className="aristocrat" src={ aristocrat } alt="aristocrat" />
      </div>
      <div>
        <img className="npc" src={ npc } alt="npc" />
      </div>
    </div>
  )
}

export default Brand