// animation.js
export function startAnimation() {
    const mask = document.querySelector('.mask');
    const spans = mask.querySelectorAll('span');
    let currentIndex = 0;
  
    setInterval(function () {
      const current = mask.querySelector('span[data-show]');
      const nextIndex = (currentIndex + 1) % spans.length;
      const next = spans[nextIndex];
  
      current.removeAttribute('data-show');
      current.removeAttribute('data-up');
  
      next.setAttribute('data-show', '');
  
      currentIndex = nextIndex;
    }, 2000);
  }
  