import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./contactPage.css";
import HeaderImage from "../../assets/contactPage/contactPage.png";
import { useState } from "react";
import Swal from "sweetalert2";
import CanvasComponent from "../../components/cctv/Cctv";
import Cable from "../../components/cable/Cable";
import axios from "axios";

const data = [
  "Structured Cabling",
  "Command Center Set Up",
  "Internet Services",
  "Mobile Applications",
  "Network Installation & Configuration",
  "Learning Management System (LMS) Platform",
  "CCTV Installation",
  "Piso Wifi",
  "School Portal System",
  "Inventory and Sales System",
];

function ContactPage() {
  useEffect(() => {
    document.title = "Nazdaa Contact"; // Update the title here
  }, []);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    jobTitle: "",
    subject: "",
    inquiry: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      // Show loading indicator
      setIsLoading(true);

      // Send form data to the server
      await axios.post("https://nazdaa.net/connect.php", formData);

      // Reset form data
      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        company: "",
        jobTitle: "",
        subject: "",
        inquiry: "",
      });

      // Show success message
      Swal.fire("Success", "Your form has been submitted!", "success");
    } catch (error) {
      // Show error message
      Swal.fire(
        "Error",
        "An error occurred while submitting the form.",
        "error"
      );
    } finally {
      // Hide loading indicator
      setIsLoading(false);
    }
  };

  const [inquiryError, setInquiryError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Additional validation for the 'inquiry' field
    if (name === "inquiry") {
      const wordCount = value.trim().split(/\s+/).length;
      if (wordCount < 10) {
        setInquiryError("!! Please enter at least 10 words !!");
      } else {
        setInquiryError("");
      }
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="nazdaa__contactsPage">
      <Navbar />
      <div className="nazdaa__contactsPage-heading section__padding">
        <div className="nazdaa__contactsPage-heading__content">
          <center>
            <h1>Let's Connect and Transform Together!</h1>
          </center>
        </div>
        <img src={HeaderImage} alt="" />
      </div>
      <div className="nazdaa__contactsPage-body section__padding">
        <div className="nazdaa__contactsPage-body__form">
          <form method="POST" onSubmit={handleSubmit} key={formData}>
            <div className="nazdaa__contactsPage-body__tag">
              <h1>It's about</h1>
              <select
                name="tag"
                id="tag"
                onChange={(e) =>
                  setFormData({ ...formData, subject: e.target.value })
                }
                value={formData.subject}
                required
              >
                <option value="">Please select a service</option>
                {data.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <h2>
              Ask us anything regarding the service you want to discuss about!
            </h2>

            <div className="form-group fullwidth">
              <div className="email-input">
                <label htmlFor="email">Email*</label> <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={formData.email}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="name-inputs">
                <div className="name-input">
                  <label htmlFor="first_name">First Name*</label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    required
                    onChange={handleChange}
                    value={formData.first_name}
                  />
                </div>
                <div className="name-input">
                  <label htmlFor="last_name">Last Name*</label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    required
                    onChange={handleChange}
                    value={formData.last_name}
                  />
                </div>
              </div>
            </div>

            <div className="form-group fullwidth">
              <label htmlFor="company">Company*</label> <br />
              <input
                type="text"
                id="company"
                name="company"
                required
                onChange={handleChange}
                value={formData.company}
              />
            </div>

            <div className="form-group fullwidth">
              <label htmlFor="jobTitle">Job Title*</label> <br />
              <input
                type="text"
                id="jobTitle"
                name="jobTitle"
                required
                onChange={handleChange}
                value={formData.jobTitle}
              />
            </div>

            <div className="form-group fullwidth">
              {inquiryError && <p className="error-message">{inquiryError}</p>}
              <label htmlFor="inquiry">Your inquiry*</label> <br />
              <textarea
                id="inquiry"
                name="inquiry"
                required
                onChange={handleChange}
                value={formData.inquiry}
              />
              <p>Include all the details you can share, thank you!</p>
            </div>

            <div className="button">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
        {isLoading && <div className="loading-overlay" />}
        {isLoading && (
          <div className="loading-container">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}

        <div className="cctv-animation">
          <CanvasComponent />
        </div>
      </div>
      <div className="cable-animation">
        <Cable />
      </div>
      <Footer />
    </div>
  );
}

export default ContactPage;
